<template>
  <AdminPage>
      <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <div 
            class="field has-addons"
          >
            <p class="control">
              <span class="select">
                <select>
                  <option>All timeframes</option>
                  <!-- <option>this YTD</option>
                  <option>last Year</option>
                  <option>this payout period</option>
                  <option>this MTD</option> -->
                </select>
              </span>
            </p>
            <p class="control">
              <span class="select">
                <select>
                  <option>All customers</option>
                  <!-- <option>Top 10% transactions</option>
                  <option>other</option> -->
                </select>
              </span>
            </p>
            <p class="control">
              <span class="select">
                <select>
                  <option>All locations</option>
                  <!-- <option>Burlington, VT</option>
                  <option>..</option> -->
                </select>
              </span>
            </p>
          </div>
        </div>
      </div>
    </nav>

    <section class="has-background-info has-text-light" style="padding:20px;">
      <nav class="level">
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Users</p>
            <p class="title has-text-light">
              {{ stats.customer_total }}
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Businesses</p>
            <p class="title has-text-light">
              {{ stats.business_total }}
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Transactions</p>
            <p class="title has-text-light">
              {{ stats.transactions_total }}
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Min Spend</p>
            <p class="title has-text-light">
               {{ USD(stats.spend_total).format() }}
            </p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Estimated ROI</p>
            <p class="title has-text-light">
               {{ stats.estimated_roi }}
            </p>
          </div>
        </div>
      </nav>
    </section>

    <section class="section content">
      <p class="is-size-4"><b>{{stats.customer_total}}</b> users shopped at <b>{{stats.business_shopped_total}}</b> businesses. They spent over <b>{{ USD(stats.spend_total).format() }}</b> to earn <b>{{ USD(stats.award_total).format() }}</b>.</p>
      <p class="is-size-4">Average ROI is <b>{{stats.estimated_roi}}</b> for businesses that offer deals on Local Loot</p>
      <hr/>
    </section>
  </AdminPage>
</template>


<script>
import AdminPage from "@/components/AdminPage.vue";
import api from "@/service/api.js";

export default {
  components: {
    AdminPage
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
  },
  data() {
    return {
      stats: []
    }
  },
  mounted() {
    api.getAdminStats(this.currentUser.slug)
      .then(resp => {
        this.stats = resp.data
      })
      .catch(error => {
        this.error = error.data
      })
  },
}
</script>
